<template>
  <div class="view view--protocole--add">
    <div class="view-header view-header--protocol-details"
      v-if="pageTitle">
      <div class="view-header__header">
        <template v-if="$route.name.includes('Edit')">
          <Btn v-if="protocol?.statut?.uid !== 'ENCOURS'"
            text="Supprimer le protocole"
            icon="trash-alt"
            color="white"
            @click="beforeDelete"
            hollow />
        </template>
      </div>
      <div class="view-header__footer">

        <BtnBack v-if="$route.name.includes('Edit')"
          :to="{ 'name': 'protocol', 'id': protocol.id }" />
        <BtnBack v-else
          :to="{ 'name': 'protocols' }" />

        <h1 class="page-title">
          {{
              $route.name.includes("Edit")
                ? `Édition d'un protocole - ${protocol?.titre}`
                : "Création d'un protocole"
          }}
        </h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section--border-bottom bg-gray-lightestest"
        v-if="!$route.name.includes('Edit')">
        <Container>
          <Timeline :index="step" :total="8"
            :lastStepToRight="false" />
        </Container>
      </Section>

      <Section class="section--no-padding"
        v-else>
        <TabsMaterial :tabs="[
          {
            urlName: 'protocolGeneralInformationEdit',
            params: { id: protocol.id },
            text: 'Informations générales',
          },
          {
            urlName: 'protocolExperimentImplementationEdit',
            params: { id: protocol.id },
            text: 'Implantation des essais',
          },
          {
            urlName: 'protocolStudiedExperimentEdit',
            params: { id: protocol.id },
            text: 'Facteurs étudiés',
          },
          {
            urlName: 'protocolCoverageExperimentEdit',
            params: { id: protocol.id },
            text: 'Couvertures sur l\'essai',
          },
          {
            urlName: 'protocolPhytosanitaryProductsEdit',
            params: { id: protocol.id },
            text: 'Produits phytosanitaires',
          },
          {
            urlName: 'protocolStatisticalTestsEdit',
            params: { id: protocol.id },
            text: 'Tests statistiques',
          },
          {
            urlName: 'protocolRatingCalendarEdit',
            params: { id: protocol.id },
            text: 'Calendrier des notations',
          },
          {
            urlName: 'protocolVersionsAndValidationEdit',
            params: { id: protocol.id },
            text: isBpe ? 'Versions et validations' : 'Versions et validation',
          },
        ]" />
      </Section>

      <form @submit.prevent="onSubmit(true)">
        <router-view v-model="submitted"
          :validationSchema="validationSchema"
          @set-step="setStep($event)"
          @set-route="
            (event) => {
              this.routeName = event.routeName;
              this.previousRouteName = event.previousRouteName;
              this.nextRouteName = event.nextRouteName;
              this.routeParams = event.routeParams;
            }
          "
          @populate="populateForm($event)"
          @form-submitted="handleSubmit($event)"
          @set-submitted="onSubmit"></router-view>

        <div class="view-footer">
          <div class="view-actions view-actions--left">
            <Btn v-if="step !== 7"  text="Annuler" :to="{ name: 'protocols' }" />
            <!-- Create buttons -->
            <template v-if="!$route.name.includes('Edit')">
              <Btn v-if="previousRouteName"
                :to="{ name: previousRouteName, params: routeParams }"
                text="Précédent"
                icon="chevron-big-left"
                hollow />
              <Btn v-if="step !== 3 && step !== 8"
                btnType="submit"
                text="Suivant"
                color="primary"
                icon="chevron-big-right"
                icon-after />

              <Btn v-if="step === 3"
                :to="{ name: nextRouteName, params: routeParams }"
                text="Suivant"
                color="primary"
                icon="chevron-big-right"
                icon-after />

              <Btn v-if="step === 8 && isBpe"
                btnType="submit"
                text="Enregistrer"
                color="primary" />

              <Btn v-if="protocol?.statut?.uid !== 'VALIDE' && step === 8"
                :to="{ name: 'protocols' }"
                btnType="submit"
                text="Enregistrer le protocole comme brouillon"
                color="default" />

            </template>
            <!-- Edit buttons -->
            <template v-else>
              <Btn v-if="step !== 3 && step !== 7 && step !== 8"
                btnType="submit"
                text="Enregistrer"
                color="primary" />

              <Btn v-if="protocol?.statut?.uid !== 'VALIDE' && step === 8"
                :to="{ name: 'protocols' }"
                btnType="submit"
                text="Enregistrer le protocole comme brouillon"
                color="default" />

            </template>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- Modal -->
  <Modal title="Supprimer le protocole ?"
    :active="modal.deleteProtocol"
    :data="modal.data"
    @modal-close="modal.deleteProtocol = false">
    <template v-slot:modal-body>

      <p v-if="modal.partage" ><b class="color-warning">Attention, le protocole a été proposé au partage.</b></p>
      <p>Voulez vous vraiment supprimer le protocole&nbsp;?</p>

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler"
        @click="modal.deleteProtocol = false" />
      <Btn text="Supprimer"
        @click="deleteProtocole()"
        icon="user-times"
        color="primary" />
    </template>
  </Modal>

  <NavigationDropdownProtocol v-if="$route.name.includes('Edit')" />
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import BtnBack from '@/components/base/BtnBack.vue'
import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import Timeline from '@/components/layout/Timeline.vue'
import Modal from '@/components/layout/Modal.vue'
import NavigationDropdownProtocol from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'

export default {
  name: 'ProtocolAddEditView',

  components: {
    NavigationDropdownProtocol,
    Modal,
    Btn,
    BtnBack,
    Section,
    Container,
    TabsMaterial,
    Timeline,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    const validationSchema = [
      // premiere étape présente à toutes les étapes [0]
      this.yup.object({
        bpe: this.yup.boolean().nullable(),
        thematique_uid: this.yup.object().required(),
        titre: this.yup.string().required(),
        annee_recolte_id: this.yup.object().required(),
        objectifs: this.yup.string().nullable(),
        prestation_service: this.yup.boolean().nullable(),
        agriculture_biologique: this.yup.boolean().nullable(),
        code_entite: this.yup.string().nullable(),
        type_uid: this.yup.object().required(),
        code_national: this.yup.string().nullable(),
        national_qualification: this.yup.string().nullable(),
        type_qualification: this.yup.string().nullable(),
        objectif_qualification: this.yup.string().nullable(),
        donneur_ordre: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        donneur_ordre_code: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        donneur_ordre_contact: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        code_regional: this.yup.string().nullable(),
        declaration_protocole_id: this.yup.object().nullable(),
        numero_devis: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        cultures: this.yup.array().required(),
        sous_thematiques: this.yup.array().nullable(),
      }),
      // 2eme étape [1]
      this.yup.object({
        cibles: this.yup.object().nullable(),
        situation_a_favoriser: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        typedispositif_uid: this.yup.object().nullable(),
        nombre_blocs: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        surface_microparcelles: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        implantation_temoins_id: this.yup
          .object()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        secteur_geographique: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        orientation_semis: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        type_sol: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        commentaires: this.yup.string().nullable(),
      }),
      // 4 eme étape [2]
      this.yup.object({
        couvertures_possibles: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        couvertures_interdites: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
      }),
      // 5eme étape [3]
      this.yup.object({
        mode_appl_phyto: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        volume_bouillie: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        unite_uid: this.yup
          .object()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        observations: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        contamination_artificielle: this.yup.boolean().nullable(),
        brumisation: this.yup.boolean().nullable(),
        utilisation_phyto: this.yup.boolean().nullable(),
        methode_ceb_reference: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        methodes_internes: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
        guidelines_oepp_reference: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
      }),
      // 6 eme étape [4]
      this.yup.object({
        tests_statistiques: this.yup
          .string()
          .when(['bpe'], (bpe, schema) => (bpe ? schema.required() : schema.nullable()))
          .nullable(),
      }),
      // Modale 6eme étape [5]
      this.yup.object({
        designation: this.yup.string().required(),
        date_previsionnelle: this.yup.date().nullable(),
        stade_uid: this.yup.object().nullable(),
        observations: this.yup.string().nullable(),
      }),
      // Modale 5eme étape [6]
      this.yup.object({
        produit_id: this.yup.object().required().nullable(),
        derogation_destruction_recolte: this.yup.boolean().nullable(),
        permis_experimenter_ade: this.yup.string().nullable(),
        ref_expedition_produits_fabricant: this.yup.string().nullable(),
      }),
      // Utile à 7eme étape [7]
      this.yup.object({
        trigramme: this.yup
          .string()
          .required()
          .min(3, 'Le trigramme doit contenir 3 caractères')
          .max(3),
      }),
    ]

    return {
      submitted: false,
      validationSchema,
      isBpe: false,
      schema: {},
      step: 0,
      routeName: null,
      previousRouteName: null,
      nextRouteName: null,
      routeParams: {},
      method: 'post',
      protocol: {},
      componentKey: 0,
      protocolId: this.$route.params.id ?? null,
      modal: {
        deleteProtocol: false,
        partage: false,
      },
    }
  },

  watch: {
    previousRouteName() {
      this.$nextTick(() => {
        this.componentKey += 1
      })
    },
  },

  created() {
    if (this.$route.params.id) {
      this.method = 'put'
    }
  },

  methods: {
    onSubmit(submitted = false) {
      this.submitted = submitted
    },
    populateForm(event) {
      this.protocol = event
      if (
        this.protocol.statut
        && this.protocol.statut.uid === 'ENCOURS'
        && !this.$route.name.includes('protocolStudiedExperimentEdit')
      ) {
        this.$router.push({ name: 'forbidden', params: { fromProtocole: true } })
      }

      this.isBpe = this.protocol.bpe ?? false
      this.protocolService.setProtocol(this.protocol)
      this.protocolService.setFormService(this.formService)
      this.protocolService.populateForm()
    },

    setStep(event) {
      this.step = event
    },

    async beforeDelete() {
      const childeResponse = await this.fetchService.get(`protocole/${this.$route.params.id}/enfants`)
      if (childeResponse.meta.pagination.count > 0) {
        this.modal.partage = true
      }
      this.modal.deleteProtocol = true
    },

    async deleteProtocole() {
      this.emitter.emit('open-loader')
      const { id } = this.$route.params
      this.fetchService.delete(`protocole/${id}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le protocole a été supprimé.',
          })
          this.emitter.emit('list-refresh')
          this.emitter.emit('close-loader')
          this.$router.push({ name: 'protocols' })
          this.modal.deleteItems = false
        },
        (responseError) => {
          this.emitter.emit('close-loader')
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
        },
      )
    },

    handleSubmit(values) {
      this.emitter.emit('open-loader')
      this.submitted = false
      const postParams = { ...values }

      if (postParams.cultures && postParams.cultures.length) {
        postParams.cultures = postParams.cultures.map((culture) => ({
          uid: culture.key,
        }))
      }
      if (postParams.sous_thematiques && postParams.sous_thematiques.length) {
        postParams.sous_thematiques = postParams.sous_thematiques.map(
          (item) => ({ uid: item.key }),
        )
      }

      postParams.annee_recolte_id = postParams.annee_recolte_id?.key

      if (!this.protocol.type) {
        this.protocol.type = { uid: null }
      }

      postParams.type_uid = postParams.type_uid?.key || this.protocol.type.uid

      if (postParams.cibles && postParams.cibles.length) {
        postParams.cibles = postParams.cibles.map((culture) => ({
          id: culture.key,
        }))
      }

      if (!this.protocol.thematique) {
        this.protocol.thematique = { uid: null }
      }

      postParams.thematique_uid = postParams.thematique_uid?.key || this.protocol.thematique.uid

      postParams.declaration_protocole_id = postParams.declaration_protocole_id?.key

      postParams.typedispositif_uid = postParams.typedispositif_uid?.key

      postParams.implantation_temoins_id = postParams.implantation_temoins_id?.key

      postParams.unite_uid = postParams.unite_uid?.key

      let endPoint = 'protocole'

      if (this.protocol.id) {
        this.method = 'put'
        endPoint = `protocole/${this.protocol.id}`
      }

      this.fetchService[this.method](endPoint, postParams)
        .then(
          (response) => {
            this.emitter.emit('close-loader')
            this.protocol = response.data
            this.populateForm(this.protocol)

            if (this.nextRouteName) {
              const params = {}
              if (this.$route.params.id) {
                params.id = this.$route.params.id
              } else {
                params.id = response.data.id
              }

              if (this.$route.name.includes('Edit')) {
                this.emitter.emit('alert', {
                  type: 'success',
                  content: 'Le protocole a bien été mise à jour.',
                })
              } else {
                if (this.step === 7) {
                  this.$router.push({ name: 'protocols' })
                }

                this.$router.push({ name: this.nextRouteName, params })
              }
            }
          },
          (error) => {
            this.formService.handleApiError(error)
            this.populateForm(this.protocol)
          },
        )
        .finally(() => this.emitter.emit('close-loader'))
    },

    sanitizeValues(values) {
      if (Object.keys(values).length) {
        Object.keys(values).forEach((key) => {
          if (values[key] === null || typeof values[key] === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            delete values[key]
          }
        })
      }
      return values
    },
  },
}
</script>
